import { Component } from "react";
import validator from "validator";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BsFillPersonCheckFill,
  BsFillPersonLinesFill,
  GiGraduateCap,
} from "react-icons/all";
import "./CoachForm.css";

//cofiguring toast notification
toast.configure();

class CoachForm extends Component {
  //Initializing a state
  state = {
    name: "",
    position: "",
    qualification: "",
    profile: "",
    nameValidation: "",
    positionValidation: "",
    qualificationValidation: "",
    profileValidation: "",
    file_size: 0,
  };

  //taking the file's input
  onChangeFileHandler = (e) => {
    this.setState({
      profile: e.target.files[0],
      file_size: e.target.files[0].size,
    });
  };

  //notification
  notify = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
    });
  error = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
    });

  addCoach(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("position", this.state.position);
    formData.append("qualification", this.state.qualification);
    formData.append("profile", this.state.profile);

    if (
      validator.isEmpty(this.state.name) ||
      validator.isEmpty(this.state.position) ||
      validator.isEmpty(this.state.qualification)||
      this.state.file_size === 0
    ) {
      this.error("Please provide the required information!");

      if (validator.isEmpty(this.state.name)) {
        this.setState({
          name_validate: "Full name is required!",
        });
      }
      if (validator.isEmpty(this.state.position)) {
        this.setState({
          price_validate: "Position is required!",
        });
      }
      if (validator.isEmpty(this.state.qualification)) {
        this.setState({
          quality_validate: "Qualification is required!",
        });
      }
    } else {
      Axios.post("https://mindsinaction.com.na/api/coach.php", formData)
        .then((res) => {
          switch (res.data.message) {
            case "success":
              this.notify("Successfully added coach!");
              window.location.reload(false);
              break;
            case "file_size_err":
              this.errer(
                "Only images images less than 500MB are allowed!"
              );
              break;
            case "empty_file":
              this.setState({
                validate_profile: "Product image is required!",
              });
              break;
            case "file_type_err":
              this.setState({
                validate_profile: "Only images of this type are allowed!",
              });
              break;
            case "coach_exist":
              this.error("Coach already exists!");
              break;
            default:
              break;
          }

          console.log(formData);
        })
        .catch((error) => {
          this.error(error.message);
        });
    }
  }

  render() {
    return (
      <div>
        <div className="coach-main">
          <form className="coach-form">
            <p className="error">{this.state.nameValidation}</p>
            <div className="coach-form-control">
              <div className="input">
                <BsFillPersonCheckFill className="icon" />
                <input
                  type="text"
                  placeholder="Full name"
                  onChange={(e) =>
                    this.setState({
                      name: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <p className="error">{this.state.positionValidation}</p>
            <div className="coach-form-control">
              <div className="input">
                <BsFillPersonLinesFill className="icon" />
                <input
                  type="text"
                  placeholder="Position"
                  onChange={(e) =>
                    this.setState({
                      position: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <p className="error">{this.state.qualificationValidation}</p>
            <div className="coach-form-control">
              <div className="input">
                <GiGraduateCap className="icon" id="qua-icon" />
                <textarea
                  placeholder="Qualification"
                  onChange={(e) =>
                    this.setState({
                      qualification: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <p className="error">{this.state.profileValidation}</p>
            <div className="coach-form-control" id="profile-container">
              <label htmlFor="Profile">Profile picture</label>
              <input
                type="file"
                accept="image/*"
                onChange={this.onChangeFileHandler}
              />
            </div>
            <div className="coach-form-control">
              <input
                type="submit"
                onClick={(e) => this.addCoach(e)}
                value="Add caoch"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default CoachForm;
