import React, { useState } from "react";
import "react-tabs/style/react-tabs.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Admin.css";
import Header from "../header/Header";
import Popup from "../Popup/Popup";
import { BsSearch, IoIosPersonAdd } from "react-icons/all";
import AdminForm from "./AdminForm/AdminForm";
import { Redirect } from "react-router-dom";

toast.configure();

export default function Admin() {
  const [openPopup, setOpenPopup] = useState(false);

  if (sessionStorage.getItem("status") != null) {
  return (
    <>
      <Header />
      <div className="admin-main-home">
        <div className="top-search-add">
          <div className="search">
            <div className="search-container">
              <input type="text" name="" id="" placeholder="Search..." />
              <BsSearch className="search-icon" />
            </div>
          </div>
          <div className="add">
            <button onClick={() => setOpenPopup(true)}>
              <IoIosPersonAdd className="add-icon" />
              Add user
            </button>
          </div>
        </div>
        <Popup
          title="Add user"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <AdminForm />
        </Popup>
      </div>
    </>
  );
  }else{
    return <Redirect to="/"/>
  }
}
