import Axios from "axios";
import React, { useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { CgAdd } from "react-icons/cg";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Redirect } from "react-router";
import Header from "../header/Header";
import Popup from "../Popup/Popup";
import Popups from 'reactjs-popup';
import CoachForm from "./CoachForm/CoachForm";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Coach() {
  const [coach, setCoach] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  //notification
  const notify = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
    });
  const errorNotify = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
    });

  const warning = (msg) => {
    toast.warning(msg, { position: toast.POSITION.TOP_CENTER });
  };

  const deleteCoach = (id) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this product?"
    );
    if (confirm === true) {
      Axios.get(
        "https://mindsinaction.com.na/api/delete.php?coach_id=" + id + "&delete_type=coach"
      )
        .then((response) => {
          switch (response.data.message) {
            case "success":
              notify("Successfully deleted!");
              break;
            case "error":
              errorNotify("Failed to delete product");
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          errorNotify(error.message);
        });
    } else {
      warning("Delete cancelled!");
    }
  };

  //fetching coaches from the database
  useEffect(() => {
    Axios.get("https://mindsinaction.com.na/api/coach.php")
      .then((response) => {
        console.log(response);
        setCoach(response.data);
      })
      .catch((error) => {
        console.log(error);
        window.addEventListener("unhandledrejection", function (event) {
          console.error(
            "Unhandled rejection (promise: ",
            event.promise,
            ", reason: ",
            event.reason,
            ")."
          );
        });
      });
  }, []);

  if (sessionStorage.getItem("status") != null) {
    return (
      <div>
        <Header />
        <div className="admin-product-main">
          <div className="product-table-container">
            <h1>Shop products</h1>
            <div className="top-search-add">
              <div className="search">
                <div className="search-container">
                  <input type="text" name="" id="" placeholder="Search..." />
                  <BsSearch className="search-icon" />
                </div>
              </div>
              <div className="add">
                <button onClick={() => setOpenPopup(true)}>
                  <CgAdd className="add-icon" />
                  Add coach
                </button>
              </div>
            </div>
            <Popup
              title="Add product"
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
            >
              <CoachForm />
            </Popup>
            <table>
              <tr>
                <th>Full name</th>
                <th>Position</th>
                <th>Profile</th>
                <th>Actions</th>
              </tr>
              {coach.map((x, i) => {
                return (
                  <tr key={i}>
                    <td>{x.name}</td>
                    <td>{x.position}</td>
                    <td>
                      <img
                        src={
                          "https://mindsinaction.com.na/api/coach/" + x.profile
                        }
                        alt=""
                      />
                    </td>
                    <td className="buttons">
                      <Popups
                        trigger={
                          <button className="update">
                            <BiEdit />
                          </button>
                        }
                        position="bottom center"
                      >
                        <div>
                          <form
                            method="POST"
                            enctype="multipart/form-data"
                            action="https://mindsinaction.com.na/api/coach_update.php"
                          >
                            <div className="form-control">
                              <input
                                type="hidden"
                                name="old_coach_name"
                                value={x.name}
                              />
                            </div>
                            <div className="form-control">
                              <input
                                type="text"
                                name="name"
                                placeholder={x.name}
                              />
                            </div>
                            <div className="form-control">
                              <input
                                type="text"
                                name="position"
                                placeholder={x.position}
                              />
                            </div>
                            <div className="form-control">
                              <input
                                type="text"
                                name="qualification"
                                placeholder={x.qualification}
                              />
                            </div>
                            <div className="form-control">
                              <input
                                type="file"
                                name="image"
                                accept="image/*"
                              />
                            </div>
                            <div className="form-control">
                              <input type="submit" name="submit" />
                            </div>
                          </form>
                        </div>
                      </Popups>

                      <button
                        onClick={() => deleteCoach(x.id)}
                        className="delete"
                      >
                        <RiDeleteBin5Line />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    );
  } else {
    return <Redirect to="/" />;
  }
}
