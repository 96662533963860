import { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import Axios from "axios";
import "./StudentForm.css";

class StudentForm extends Component {
  state = {
    course: "",
    firstname: "",
    lastname: "",
    age: "",
    address: "",
    school: "",
    grade: "",
    parentName: "",
    emailAddress: "",
    phoneNumber: "",
    proof: "",
    courseValidation: "",
    firstnameValidation: "",
    lastnameValidation: "",
    ageValidation: "",
    addressValidation: "",
    schoolValidation: "",
    gradeValidation: "",
    parent_nameValidation: "",
    emailValidation: "",
    phone_numberValidation: "",
    fileSize: 0,
    fileValidation: "",
  };

  //notification
  notify = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
    });
  errerNotify = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
    });

  //file collection function
  onChangeFileHandler = (e) => {
    this.setState({
      proof: e.target.files[0],
      fileSize: e.target.files[0].size,
    });
  };

  //Student's registration backend api
  registerStudent(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("course", this.state.course);
    formData.append("firstname", this.state.firstname);
    formData.append("lastname", this.state.lastname);
    formData.append("age", this.state.age);
    formData.append("address", this.state.address);
    formData.append("school", this.state.school);
    formData.append("grade", this.state.grade);
    formData.append("parentName", this.state.parentName);
    formData.append("emailAddress", this.state.emailAddress);
    formData.append("phoneNumber", this.state.phoneNumber);
    formData.append("proof", this.state.proof);

    if (
      validator.isEmpty(this.state.course) ||
      validator.isEmpty(this.state.firstname) ||
      validator.isEmpty(this.state.lastname) ||
      validator.isEmpty(this.state.age) ||
      validator.isEmpty(this.state.address) ||
      validator.isEmpty(this.state.school) ||
      validator.isEmpty(this.state.grade) ||
      validator.isEmpty(this.state.parentName) ||
      validator.isEmpty(this.state.emailAddress) ||
      validator.isEmpty(this.state.phoneNumber)
      // Object.keys(this.state.proof).length === 0
    ) {
      this.errerNotify("Please, provide the required details!");
      if (validator.isEmpty(this.state.course)) {
        this.setState({
          courseValidation: "Please, select a course!",
        });
      }
      if (validator.isEmpty(this.state.firstname)) {
        this.setState({
          firstnameValidation: "First name is required!",
        });
      }
      if (validator.isEmpty(this.state.lastname)) {
        this.setState({
          lastnameValidation: "Last name is required!",
        });
      }
      if (validator.isEmpty(this.state.age)) {
        this.setState({
          ageValidation: "Age is required!",
        });
      }
      if (validator.isEmpty(this.state.address)) {
        this.setState({
          addressValidation: "Address is required!",
        });
      }
      if (validator.isEmpty(this.state.school)) {
        this.setState({
          schoolValidation: "School is required!",
        });
      }
      if (validator.isEmpty(this.state.grade)) {
        this.setState({
          gradeValidation: "Grade is required!",
        });
      }
      if (validator.isEmpty(this.state.parentName)) {
        this.setState({
          parent_nameValidation: "Parent name is required!",
        });
      }
      if (validator.isEmpty(this.state.emailAddress)) {
        this.setState({
          emailValidation: "Email is required!",
        });
      }
      if (validator.isEmpty(this.state.phoneNumber)) {
        this.setState({
          phone_numberValidation: "Phone number is required!",
        });
      }
      if (this.state.fileSize === 0) {
        this.setState({
          fileValidation: "Proof of payment is required!",
        });
      }
    } else {
      if (!validator.isEmail(this.state.emailAddress)) {
        this.state.errerNotify("Please enter a valid email!");
      } else {
        Axios.post("https://mindsinaction.com.na/api/student.php", formData)
          .then((res) => {
            //notifying the user after sucessfull submision
            switch (res.data.message) {
              case "success":
                this.notify("Successfully added student!");
                break;
              case "file_size_err":
                this.errerNotify(
                  "Only images images less than 500MB are allowed!"
                );
                break;
              case "empty_file":
                this.setState({
                  validate_profile: "Product image is required!",
                });
                break;
              case "file_type_err":
                this.setState({
                  validate_profile: "Only images of this type are allowed!",
                });
                break;
              case "student_exist":
                this.errerNotify("Student already registered for that course!");
                break;
              case "reg_err":
                this.errerNotify("Error registering student");
                break;
              default:
                break;
            }

            for (var pair of formData.entries()) {
              console.log(pair[0] + ": " + pair[1]);
            }
          })
          .catch((error) => {
            this.errerNotify(error.message);
          });
      }
    }
  }
  render() {
    return (
      <div className="student-form">
        <form>
          <div className="form-control">
            <p className="error">{this.state.courseValidation}</p>
            <select
              onChange={(e) =>
                this.setState({
                  course: e.target.value,
                })
              }
            >
              <option value="" disabled selected hidden>
                Choose a course
              </option>
              <option value="Module 0: Dash robot for children">Module 0: Dash robot for children</option>
              <option value="Module 1: Introduction to robotics and programming">
              Module 1: Introduction to robotics and programming
              </option>
              <option value="Module 2: Scratch programming">Module 2: Scratch programming</option>
              <option value="Module 3: Basic electronics, microcontrollers and programming">
              Module 3: Basic electronics, microcontrollers and programming
              </option>
              <option value="Module 4: Electronic circuits and soldering">
              Module 4: Electronic circuits and soldering
              </option>
              <option value="Module 5: Printed Circuit board (PCB) design">
              Module 5: Printed Circuit board (PCB) design
              </option>
              <option value="Module 6: 3D printing">
              Module 6: 3D printing
              </option>
              <option value="Module 7: Solidworks and CNC">
              Module 7: Solidworks and CNC
              </option>
              <option value="Module 8: Mechatronics">
              Module 8: Mechatronics
              </option>
              <option value="Web Development">Web Development</option>
              <option value="Mobile app development">Mobile app development</option>
              <option value="Graphic Design">Graphic Design</option>
              <option value="Raspberry Pi">Raspberry Pi</option>
            </select>
          </div>
          <div className="form-control">
            <p className="error">{this.state.firstnameValidation}</p>
            <input
              type="text"
              onChange={(e) =>
                this.setState({
                  firstname: e.target.value,
                })
              }
              placeholder="First name"
            />
          </div>
          <div className="form-control">
            <p className="error">{this.state.lastnameValidation}</p>
            <input
              type="text"
              onChange={(e) =>
                this.setState({
                  lastname: e.target.value,
                })
              }
              placeholder="Last name"
            />
          </div>
          <div className="form-control">
            <p className="error">{this.state.ageValidation}</p>
            <input
              type="number"
              onChange={(e) =>
                this.setState({
                  age: e.target.value,
                })
              }
              placeholder="Age"
            />
          </div>
          <div className="form-control">
            <p className="error">{this.state.addressValidation}</p>
            <input
              type="text"
              onChange={(e) =>
                this.setState({
                  address: e.target.value,
                })
              }
              placeholder="Physical address"
            />
          </div>
          <div className="form-control">
            <p className="error">{this.state.schoolValidation}</p>
            <input
              type="text"
              onChange={(e) =>
                this.setState({
                  school: e.target.value,
                })
              }
              placeholder="School/Institution"
            />
          </div>
          <div className="form-control">
            <p className="error">{this.state.gradeValidation}</p>
            <input
              type="text"
              onChange={(e) =>
                this.setState({
                  grade: e.target.value,
                })
              }
              placeholder="Grade"
            />
          </div>
          <div className="form-control">
            <p className="error">{this.state.parent_nameValidation}</p>
            <input
              type="text"
              onChange={(e) =>
                this.setState({
                  parentName: e.target.value,
                })
              }
              placeholder="Parent full name"
            />
          </div>
          <div className="form-control">
            <p className="error">{this.state.emailValidation}</p>
            <input
              type="email"
              onChange={(e) =>
                this.setState({
                  emailAddress: e.target.value,
                })
              }
              placeholder="Email address"
            />
          </div>
          <div className="form-control">
            <p className="error">{this.state.phone_numberValidation}</p>
            <input
              type="tel"
              onChange={(e) =>
                this.setState({
                  phoneNumber: e.target.value,
                })
              }
              placeholder="Phone number"
              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
            />
          </div>
          <div className="form-control">
            <p className="error">{this.state.fileValidation}</p>
            <p>Upload payment proof</p>
            <input type="file" accept="*" onChange={this.onChangeFileHandler} />
          </div>
          <div className="form-control">
            <input
              type="submit"
              value="Submit"
              onClick={(e) => this.registerStudent(e)}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default StudentForm;
