import Axios from "axios";
import React, { useEffect, useState } from "react";
import {
  BsSearch,
  MdPlaylistAdd,
  BiEdit,
  RiDeleteBin5Line,
} from "react-icons/all";
import Header from "../header/Header";
import { Redirect } from "react-router-dom";
import BlogForm from "../Blog/BlogForm/BlogForm";
import Popup from "../Popup/Popup";
import Popups from 'reactjs-popup';
import "./Blog.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Blog() {
  const [blogData, setBlogData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  //notification
  const notify = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
    });
  const errorNotify = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
    });

  const warning = (msg) => {
    toast.warning(msg, { position: toast.POSITION.TOP_CENTER });
  };

  const deleteBlog = (id) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this product?"
    );
    if (confirm === true) {
      Axios.get("https://mindsinaction.com.na/api/delete.php?blog_id=" + id + "&delete_type=blog")
        .then((response) => {
          switch (response.data.message) {
            case "success":
              notify("Successfully deleted!");
              break;
            case "error":
              errorNotify("Failed to delete product");
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          errorNotify(error.message);
        });
    } else {
      warning("Delete cancelled!");
    }
  };

  useEffect(() => {
    Axios.get("https://mindsinaction.com.na/api/blog.php")
      .then((response) => {
        setBlogData(response.data);
      })
      .catch((error) => {
        console.log(error);
        window.addEventListener("unhandledrejection", function (event) {
          console.error(
            "Unhandled rejection (promise: ",
            event.promise,
            ", reason: ",
            event.reason,
            ")."
          );
        });
      });
  }, []);
  if (sessionStorage.getItem("status") != null) {
    return (
      <div>
        <Header />
        <div className="blog-container">
          <div className="top-search-add">
            <div className="search">
              <div className="search-container">
                <input type="text" name="name" id="" placeholder="Search..." />
                <BsSearch className="search-icon" />
              </div>
            </div>
            <div className="add">
              <button onClick={() => setOpenPopup(true)}>
                <MdPlaylistAdd className="add-icon" />
                Add blog
              </button>
            </div>
          </div>
          <Popup
            title="Add blog"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
          >
            <BlogForm />
          </Popup>
          <div className="blog-data">
            <table>
              <tr>
                <th>Title</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
              {blogData.map((x, i) => {
                return (
                  <tr key={i}>
                    <td>{x.title}</td>
                    <td>{x.date}</td>
                    <td className="buttons">

                      <Popups trigger={<button className="update">
                        <BiEdit />
                      </button>}

                        position="bottom center">
                        <div >
                        <form method="POST" enctype="multipart/form-data" action="https://mindsinaction.com.na/api/blog_update.php">
                            <div className="form-control">
                              <input type="hidden" name="id" value={x.id} />
                            </div>
                            <div className="form-control">
                              <input type="text" name="title" placeholder={x.title} />
                            </div>
                            <div className="form-control">
                              <input type="text" name="story" placeholder={x.story}/>
                            </div>
                            <div className="form-control">
                              <input type="text" name="video_url" placeholder={x.video_url}/>
                            </div>
                            <div className="form-control">
                              <input type="file" name="image" accept="image/*"/>
                            </div>
                            <div className="form-control">
                              <input type="submit" name="submit" />
                            </div>
                          </form>
                        </div>
                      </Popups>
                      <button
                        onClick={() => deleteBlog(x.id)}
                        className="delete"
                      >
                        <RiDeleteBin5Line />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    );
  } else {
    return <Redirect to="/" />;
  }
}
