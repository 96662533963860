import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import Axios from "axios";
import "./BlogForm.css";

class BlogForm extends Component {
  state = {
    tittle: "",
    videoUrl: "",
    editorState: EditorState.createEmpty(),
    blogImage: "",
    titleValidation: "",
    videoUrlValidation: "",
    editorStateValidation: "",
    file_size: 0,
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onChangeFileHandler = (e) => {
    this.setState({
      blogImage: e.target.files[0],
      file_size: e.target.files[0].size,
    });
  };

  //notification
  notify = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
    });
  errorNotify = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
    });

  //add function for submiting the blog's data
  addBlog(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", this.state.tittle);
    formData.append("video_url", this.state.videoUrl);
    formData.append(
      "story",
      draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    );
    formData.append("blog_image", this.state.blogImage);

    if (
      validator.isEmpty(this.state.tittle) ||
      this.state.editorState === EditorState.createEmpty()
    ) {
      this.errorNotify("Please, provide the required information!");
      if (validator.isEmpty(this.state.tittle)) {
        this.setState({
          titleValidation: "Title is required!",
        });
      }
      if (this.state.editorState === EditorState.createEmpty) {
        this.setState({
          editorStateValidation: "Blog story is required!",
        });
      }
      if (
        validator.isEmpty(this.state.videoUrl) ||
        this.state.file_size === 0
      ) {
        this.setState({
          videoUrlValidation:
            "Please provide one of the two(Video url or Blog image)",
        });
      }
    } else {
      Axios.post("https://mindsinaction.com.na/api/blog.php", formData)
        .then((res) => {
          switch (res.data.message) {
            case "success":
              this.notify("Successfully posted blog!");

              break;
            case "file_size_err":
              this.errorNotify(
                "Only images images less than 500MB are allowed!"
              );
              break;
            case "empty_file":
              this.setState({
                validate_profile: "Profile is required!",
              });
              break;
            case "file_type_err":
              this.setState({
                validate_profile:
                  "Only images of this type (jpg,jpeg,png) are allowed!",
              });
              break;
            case "blog_exists":
              this.errorNotify("Blog already exists!");
              break;
            default:
              break;
          }

          this.setState({
            editorState: EditorState.createEmpty(),
          });
        })
        .catch((error) => {
          this.errorNotify(error.message);
        });
    }
  }

  render() {
    return (
      <div className="main-blog-form">
        <form className="blog-form">
          <div className="blog-form-control">
            <p className="error">{this.state.titleValidation}</p>
            <input
              type="text"
              onChange={(e) =>
                this.setState({
                  tittle: e.target.value,
                })
              }
              placeholder="Blog title"
            />
          </div>
          <div className="blog-form-control">
            <input
              type="text"
              onChange={(e) =>
                this.setState({
                  videoUrl: e.target.value,
                })
              }
              placeholder="Video url(Optional)"
            />
          </div>
          <div className="blog-form-control" id="editor">
            <p className="error">{this.state.editorStateValidation}</p>
            <Editor
              editorState={this.state.editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editor"
              placeholder="Write a story here"
              onEditorStateChange={this.onEditorStateChange}
            />
          </div>
          <div className="blog-form-control">
            <p className="error">{this.state.videoUrlValidation}</p>
            <p>Upload blog image(optional)</p>
            <input
              accept="image/*"
              type="file"
              onChange={this.onChangeFileHandler}
            />
          </div>
          <div className="blog-form-control" id="s-buttons">
            <input
              type="submit"
              onClick={(e) => this.addBlog(e)}
              value="Post blog"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default BlogForm;
