import React, { Component } from "react";
import "./ProductForm.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import Axios from "axios";

toast.configure();

class ProductForm extends Component {
  state = {
    name: "",
    price: "",
    quantity: "",
    image: "",
    name_validate: "",
    price_validate: "",
    quality_validate: "",
    image_validate: "",
  };

  // I created a ref for when after presing the submit product button it will clear the form
  constructor(props) {
    super(props);
    this.myRef1 = React.createRef();
    this.myRef2 = React.createRef();
    this.myRef3 = React.createRef();
    this.myRef4 = React.createRef();
  }

  clear = () => {
    this.myRef1.current.value = "";
    this.myRef2.current.value = "";
    this.myRef3.current.value = "";
    this.myRef4.current.value = "";
  }

  //file collection function
  onChangeFileHandler = (e) => {
    this.setState({
      image: e.target.files[0],
    });
  };

  //notification
  notify = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
    });
  errorNotify = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
    });

  addProduct(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("product_name", this.state.name);
    formData.append("product_price", this.state.price);
    formData.append("quantity", this.state.quantity);
    formData.append("image_url", this.state.image);

    if (
      validator.isEmpty(this.state.name) ||
      validator.isEmpty(this.state.price) ||
      validator.isEmpty(this.state.quantity)
    ) {
      this.errorNotify("Please provide the required information!");
      if (validator.isEmpty(this.state.name)) {
        this.setState({
          name_validate: "Name is required!",
        });
      }
      if (validator.isEmpty(this.state.price)) {
        this.setState({
          price_validate: "Price is required!",
        });
      }
      if (validator.isEmpty(this.state.quantity)) {
        this.setState({
          quality_validate: "Quantity is required!",
        });
      }

    } else {
      Axios.post("https://mindsinaction.com.na/api/product.php", formData)
        .then((res) => {
          switch (res.data.message) {
            case "success":
              this.notify("Successfully added product!");
              // This is what I added
              setTimeout(() => this.clear(), 1000);
              setTimeout(() => window.location.reload(true), 1000);
              break;
            case "file_size_err":
              this.errorNotify(
                "Only images images less than 500MB are allowed!"
              );
              break;
            case "empty_file":
              this.setState({
                validate_profile: "Product image is required!",
              });
              break;
            case "file_type_err":
              this.setState({
                validate_profile: "Only images of this type are allowed!",
              });
              break;
            case "product_exist":
              this.errorNotify("Product alredy exists!");
              break;
            default:
              break;
          }

          console.log(formData);
        })
        .catch((error) => {
          this.errorNotify(error.message);
        });
    }

  }





  render() {

    return (
      <div className="product-form-main">
        <form className="product-form">
          <p class="error">{this.state.name_validate}</p>
          <div className="form-control">
            <input
              ref={this.myRef1}
              type="text"
              onChange={(e) =>
                this.setState({
                  name: e.target.value,
                })
              }
              placeholder="Product name"
            />
          </div>
          <p class="error">{this.state.price_validate}</p>
          <div className="form-control">
            <input
              ref={this.myRef2}
              type="number"
              onChange={(e) =>
                this.setState({
                  price: e.target.value,
                })
              }
              placeholder="Product price"
            />
          </div>
          <p class="error">{this.state.quality_validate}</p>
          <div className="form-control">
            <input
              ref={this.myRef3}
              type="number"
              onChange={(e) =>
                this.setState({
                  quantity: e.target.value,
                })
              }
              placeholder="Product quantity"
            />
          </div>
          <div className="form-control" id="file-control">
            <label>
              Product image <span>*</span>
            </label>
            <input ref={this.myRef4} accept="image/*" type="file" onChange={this.onChangeFileHandler} />
          </div>
          <input
            type="submit"
            onClick={(e) => this.addProduct(e)}
            value="Add product"
          />
        </form>
      </div>
    );
  }
}

export default ProductForm;
