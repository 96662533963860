import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import logo from '../../assets/login-images/logo.png';
import {
  AiFillHome,
  BsFillPersonPlusFill,
  FaRegIdCard,
  FaShoppingCart,
  MdContactPhone,
  RiArrowDownSFill,
  BiLogIn,
  AiOutlineSetting,
} from 'react-icons/all';
import './header.css';
import Cookie from 'js-cookie';

export default function Header() {
  const [click, setClick] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const handleClick = () => setClick(!click);
  const [showMenu, setShowMenu] = useState(false);
  const [userData, setUserData] = useState([]);
  let history = useHistory();

  useEffect(() => {
    setUserData(JSON.parse(Cookie.get('userData')));
  }, []);

  const changeBackground = () => {
    if (window.scrollY >= 60) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const showDropMenu = () => {
    setShowMenu(!showMenu);
  };

  //logout function
  const logout = () => {
    //clearing the user cookie
    Cookie.remove('userData');

    //clearing the session storage
    sessionStorage.clear();

    //navigating to the login page
    history.push('/');
  };

  //navigation scroll tracker
  window.addEventListener('scroll', changeBackground);

  return (
    <>
      <nav className={navbar ? 'navbar active' : 'navbar'}>
        <div className="border"></div>
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            <img src={logo} alt=""></img>
          </NavLink>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className="nav-item">
              <NavLink
                exact
                to="/home"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                <div className="nav-icons">
                  <AiFillHome />
                </div>
                Home
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/students"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                <div className="nav-icons">
                  <AiFillHome />
                </div>
                Students
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/gallery"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                <div className="nav-icons">
                  <FaRegIdCard />
                </div>
                Gallery
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/coaches"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                <div className="nav-icons">
                  <FaShoppingCart />
                </div>
                Coaches
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/product"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                <div className="nav-icons">
                  <MdContactPhone />
                </div>
                Products
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/admin"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                <div className="nav-icons">
                  <BsFillPersonPlusFill />
                </div>
                Admin
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/projects"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                <div className="nav-icons">
                  <BsFillPersonPlusFill />
                </div>
                Projects
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/blog"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                <div className="nav-icons">
                  <BsFillPersonPlusFill />
                </div>
                Blog
              </NavLink>
            </li>
            <li>
              <div className="header-profile-container">
                <div className="profile">
                  <img
                    src={
                      'https://mindsinaction.com.na/api/profile/' + userData[2]
                    }
                    alt=""
                  />
                </div>
                <div className="profile-name">
                  <p>{userData[1]}</p>
                </div>
                <div className="profile-drop">
                  <div>
                    <button className="menu-btn" onClick={() => showDropMenu()}>
                      <RiArrowDownSFill />
                    </button>

                    {showMenu ? (
                      <div className="menu">
                        <button onClick={() => logout()}>
                          Logout <BiLogIn />
                        </button>
                        <button>
                          Settings <AiOutlineSetting />
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'}></i>
          </div>
        </div>
      </nav>
    </>
  );
}
