import { Component } from 'react';
import validator from 'validator';
import Axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoNewspaperSharp } from 'react-icons/all';
import './GalleryForm.css';

//cofiguring toast notification
toast.configure();

class GalleryForm extends Component {
  state = {
    image_name: '',
    image: '',
    image_name_validation: '',
    image_validation: '',
    image_size: 0,
  };

  //taking the file's input
  onChangeFileHandler = (e) => {
    this.setState({
      image: e.target.files[0],
      image_size: e.target.files[0].size,
    });
  };

  //notification
  notify = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
    });
  error = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
    });

  //add image function
  addImage(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append('image_name', this.state.image_name);
    formData.append('image', this.state.image);

    if (
      validator.isEmpty(this.state.image_name) ||
      this.state.image_size === 0
    ) {
      this.error('Please provide the required information!');
      if (validator.isEmpty(this.state.image_name)) {
        this.setState({
          image_name_validation: 'Image name is required!',
        });
      }
      if (this.state.image_size === 0) {
        this.setState({
          image_validation: 'Image is required!',
        });
      }
    } else {
      Axios.post('https://mindsinaction.com.na/api/home_page.php', formData)
        .then((res) => {
          switch (res.data.message) {
            case 'success':
              this.notify('Successfully added image!');
              this.setState({
                image: null,
              });
              break;
            case 'file_size_err':
              this.error('Only images images less than 500MB are allowed!');
              break;
            case 'empty_file':
              this.setState({
                validate_profile: 'Post image is required!',
              });
              break;
            case 'file_type_err':
              this.setState({
                validate_profile: 'Only images of this type are allowed!',
              });
              break;
            case 'image_exist':
              this.error('Post alredy exists!');
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          this.error(error.message);
        });
    }
  }
  render() {
    return (
      <div>
        <div className="home-main">
          <form className="gallery-form">
            <p className="error">{this.state.image_name_validation}</p>
            <div className="gallery-form-control">
              <div className="input">
                <IoNewspaperSharp className="icon" id="qua-icon" />
                <input
                  type="text"
                  placeholder="Image name"
                  onChange={(e) =>
                    this.setState({
                      image_name: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <p className="error">{this.state.image_validation}</p>
            <div className="gallery-form-control" id="profile-container">
              <label htmlFor="Profile">Gallery picture</label>
              <input
                type="file"
                accept="image/*"
                onChange={this.onChangeFileHandler}
              />
            </div>
            <div className="gallery-form-control">
              <input
                type="submit"
                onClick={(e) => this.addImage(e)}
                value="Add image"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default GalleryForm;
