import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BiEdit, RiDeleteBin5Line, BsSearch, CgAdd } from 'react-icons/all';
import Header from '../header/Header';
import Popup from '../Popup/Popup';
import GalleryForm from './GalleryForm/GalleryForm';
import './Home.css';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios';
import Popups from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

toast.configure();

export default function Gallery() {
  const [image, setImage] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  //Getting product's data from backend
  useEffect(() => {
    axios
      .get('https://mindsinaction.com.na/api/home_page.php')
      .then((response) => {
        console.log(response);
        setImage(response.data);
      })
      .catch((error) => {
        console.log(error);
        window.addEventListener('unhandledrejection', function (event) {
          console.error(
            'Unhandled rejection (promise: ',
            event.promise,
            ', reason: ',
            event.reason,
            ').'
          );
        });
      });
  }, []);

  //notification
  const notify = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
    });
  const errorNotify = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
    });

  const warning = (msg) => {
    toast.warning(msg, { position: toast.POSITION.TOP_CENTER });
  };

  const deleteGallery = (id) => {
    const confirm = window.confirm(
      'Are you sure you want to delete this product?'
    );
    if (confirm === true) {
      Axios.get(
        'https://mindsinaction.com.na/api/delete.php?home_id=' +
          id +
          '&delete_type=home'
      )
        .then((response) => {
          switch (response.data.message) {
            case 'success':
              notify('Successfully deleted!');
              break;
            case 'error':
              errorNotify('Failed to delete product');
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          errorNotify(error.message);
        });
    } else {
      warning('Delete cancelled!');
    }
  };

  if (sessionStorage.getItem('status') != null) {
    return (
      <div>
        <div className="home-main">
          <Header />
          <div className="home-admin-gallery-main">
            <div className="product-table-container">
              <h1>Shop products</h1>
              <div className="top-search-add">
                <div className="search">
                  <div className="search-container">
                    <input type="text" name="" id="" placeholder="Search..." />
                    <BsSearch className="search-icon" />
                  </div>
                </div>
                <div className="add">
                  <button onClick={() => setOpenPopup(true)}>
                    <CgAdd className="add-icon" />
                    Add image
                  </button>
                </div>
              </div>
              <Popup
                title="Add image"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
              >
                <GalleryForm />
              </Popup>
              <table>
                <tr>
                  <th>Image name</th>
                  <th>Image</th>
                  <th>Date</th>
                  <th>Actions</th>
                </tr>
                {image.map((x, i) => {
                  return (
                    <tr key={i}>
                      <td>{x.image_name}</td>
                      <td>
                        <img
                          src={
                            'https://mindsinaction.com.na/api/Home/' + x.image
                          }
                          alt=""
                        />
                      </td>
                      <td>{x.date}</td>
                      <td className="buttons">
                        <Popups
                          trigger={
                            <button className="update">
                              <BiEdit />
                            </button>
                          }
                          position="bottom center"
                        >
                          <div>
                            <form
                              method="POST"
                              enctype="multipart/form-data"
                              action="https://mindsinaction.com.na/api/gallery_update.php"
                            >
                              <div className="form-control">
                                <input
                                  type="hidden"
                                  name="old_image_name"
                                  value={x.image_name}
                                />
                              </div>
                              <div className="form-control">
                                <input
                                  type="text"
                                  name="image_name"
                                  placeholder={x.image_name}
                                />
                              </div>
                              <div className="form-control">
                                <input
                                  type="file"
                                  name="image"
                                  accept="image/*"
                                />
                              </div>
                              <div className="form-control">
                                <input type="submit" name="submit" />
                              </div>
                            </form>
                          </div>
                        </Popups>

                        <button
                          onClick={() => deleteGallery(x.id)}
                          className="delete"
                        >
                          <RiDeleteBin5Line />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Redirect to="/" />;
  }
}
