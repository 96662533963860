import React from "react";
import "react-tabs/style/react-tabs.css";
import Axios from "axios";
import validator from "validator";
import { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  MdEmail,
  BiLock,
  BsFillPersonCheckFill,
  BsFillPersonLinesFill,
} from "react-icons/all";
import "./AdminForm.css";

toast.configure();

class AdminForm extends Component {
  //creating a user state
  state = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    profile: "",
    profile_size: 0,
    validate_firstname: "",
    validate_lastname: "",
    validate_email: "",
    validate_password: "",
    validate_profile: "",
  };

  //notification
  notify = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
    });
  errerNotify = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
    });

  //creating a user submit function
  addUser(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append("firstname", this.state.firstname);
    formData.append("lastname", this.state.lastname);
    formData.append("email", this.state.email);
    formData.append("password", this.state.password);
    formData.append("profile", this.state.profile);

    if (
      validator.isEmpty(this.state.firstname) ||
      validator.isEmpty(this.state.lastname) ||
      validator.isEmpty(this.state.email) ||
      validator.isEmpty(this.state.password) ||
      this.state.profile_size < 0
    ) {
      this.errerNotify("Please provide the required information!");
      if (validator.isEmpty(this.state.firstname)) {
        this.setState({
          validate_firstname: "First name is required!",
        });
      }
      if (validator.isEmpty(this.state.lastname)) {
        this.setState({
          validate_lastname: "Last name is required!",
        });
      }
      if (validator.isEmpty(this.state.email)) {
        this.setState({
          validate_email: "Email is required",
        });
      }
      if (validator.isEmpty(this.state.password)) {
        this.setState({
          validate_password: "Password is required!",
        });
      }
      if (this.state.profile_size < 0) {
        this.setState({
          validate_profile: "Profile is required!",
        });
      }
    } else {
      if (!validator.isEmail(this.state.email)) {
        this.errerNotify("Please provide a valid email!");
      }
      if (!validator.isStrongPassword(this.state.password)) {
        this.errerNotify("Please provide a strong password!");
        this.setState({
          validate_password:
            "Password should contain at least 1 uppercase letter,a number and a symbol! ",
        });
      } else {
        Axios.post("https://mindsinaction.com.na/api/addUser.php", formData)
          .then((res) => {
            switch (res.data.message) {
              case "success":
                this.notify("Successfully added user!"); 
                //this is where I made changes
                setTimeout(() => this.clear(), 1000);
                setTimeout(() => window.location.reload(true), 3000);
                break;
              case "file_size_err":
                this.errerNotify(
                  "Only images less than 500MB are allowed!"
                );
                break;
              case "empty_file":
                this.setState({
                  validate_profile: "Profile is required!",
                });
                break;
              case "file_type_err":
                this.setState({
                  validate_profile:
                    "Only images of this type (jpg,jpeg,png) are allowed!",
                });
                break;
              case "email_exist":
                this.errerNotify("Email already exists!");
                break;
              default:
                break;
            }

            console.log(formData);
          })
          .catch((error) => {
            this.errorNotify(error.message);
          });
      }
    }
    
  }
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.myRef1 = React.createRef();
    this.myRef2 = React.createRef();
    this.myRef3 = React.createRef();
    this.myRef4 = React.createRef();
    this.myRef5 = React.createRef();
    this.myRef6 = React.createRef();
  }

  clear = () => {
    this.myRef1.current.value  =  "";
    this.myRef2.current.value  =  "";
    this.myRef3.current.value  =  "";
    this.myRef4.current.value  =  "";
    this.myRef5.current.value  =  "";
    this.myRef6.current.value  =  "";
       }

  //file collection function
  onChangeFileHandler = (e) => {
    this.setState({
      profile: e.target.files[0],
      profile_size: e.target.files[0].size,
    });
  };

  render() {
    return (
      <>
        <div className="admin-main">
          <form className="admin-form">
            <p className="error">{this.state.validate_firstname}</p>
            <div className="admin-form-control">
              <div className="input">
                <BsFillPersonCheckFill className="icon" />
                <input
                ref={this.myRef1} //this is where I made changes
                  type="text"
                  placeholder="First name"
                  onChange={(e) =>
                    this.setState({
                      firstname: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <p className="error">{this.state.validate_lastname}</p>
            <div className="admin-form-control">
              <div className="input">
                <BsFillPersonLinesFill className="icon" />
                <input
                ref={this.myRef2} //this is where I made changes
                  type="text"
                  placeholder="Last name"
                  onChange={(e) =>
                    this.setState({
                      lastname: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <p className="error">{this.state.validate_email}</p>
            <div className="admin-form-control">
              <div className="input">
                <MdEmail className="icon" />
                <input
                ref={this.myRef3} //this is where I made changes
                  type="email"
                  placeholder="Email"
                  onChange={(e) =>
                    this.setState({
                      email: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <p className="error">{this.state.validate_password}</p>
            <div className="admin-form-control">
              <div className="input">
                <BiLock className="icon" />
                <input
                ref={this.myRef4} //this is where I made changes
                  type="password"
                  placeholder="Password"
                  onChange={(e) =>
                    this.setState({
                      password: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <p className="error">{this.state.validate_profile}</p>
            <div className="admin-form-control" id="profile-container">
              <label htmlFor="Profile">Profile picture</label>
              <input
              ref={this.myRef5} //this is where I made changes
                type="file"
                accept="image/*"
                onChange={this.onChangeFileHandler}
              />
            </div>
            <div className="admin-form-control">
              <input
              ref={this.myRef6} //this is where I made changes
                type="submit"
                onClick={(e) => this.addUser(e)}
                value="Add user"
              />
            </div>
          </form>
        </div>
      </>
    );
  }
}


export default AdminForm;
