import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import "./Product.css";
import Axios from "axios";
import { BiEdit, RiDeleteBin5Line, BsSearch, CgAdd } from "react-icons/all";
import ProductForm from "./ProductForm/ProductForm";
import Popups from "../Popup/Popup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router-dom";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

toast.configure();
// delete .php doesn't delete image from files
export default function Product() {
  const [product, setProduct] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  //Getting product's data from backend
  useEffect(() => {
    Axios.get("https://mindsinaction.com.na/api/product.php")
      .then((response) => {
        console.log(response);
        setProduct(response.data);
      })
      .catch((error) => {
        console.log(error);
        window.addEventListener("unhandledrejection", function (event) {
          console.error(
            "Unhandled rejection (promise: ",
            event.promise,
            ", reason: ",
            event.reason,
            ")."
          );
        });
      });
  }, []);

  //notification
  const notify = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
    });
  const errorNotify = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
    });

  const warning = (msg) => {
    toast.warning(msg, { position: toast.POSITION.TOP_CENTER });
  };

  const deleteProduct = (id) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this product?"
    );
    if (confirm === true) {
      Axios.get("https://mindsinaction.com.na/api/delete.php?product_id=" + id + "&delete_type=product")
        .then((response) => {
          switch (response.data.message) {
            case "success":
              notify("Successfully deleted!");
              // This is what I added
              // localStorage.removeItem(id);
              setTimeout(() => window.location.reload(true), 1000);
              break;
            case "error":
              errorNotify("Failed to delete product");
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          errorNotify(error.message);
        });

    } else {
      warning("Delete cancelled!");
    }
  };

  if (sessionStorage.getItem("status") != null) {
    return (
      <div className="product-main">
        <Header />
        <div className="admin-product-main">
          <div className="product-table-container">
            <h1>Shop products</h1>
            <div className="top-search-add">
              <div className="search">
                <div className="search-container">
                  <input type="text" name="" id="" placeholder="Search..." />
                  <BsSearch className="search-icon" />
                </div>
              </div>
              <div className="add">
                <button onClick={() => setOpenPopup(true)}>
                  <CgAdd className="add-icon" />
                  Add product
                </button>
              </div>
            </div>
            <Popups
              title="Add product"
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
            >
              <ProductForm />
            </Popups>
            <table>
              <tr>
                <th>Name</th>
                <th>Picture</th>
                <th>Price</th>
                <th>Quantiyty</th>
                <th>Actions</th>
              </tr>
              {product.map((x, i) => {
                return (
                  <tr key={i}>
                    <td>{x.product_name}</td>
                    <td>
                      <img
                        src={
                          "https://mindsinaction.com.na/api/product/" +
                          x.image_url
                        }
                        alt=""
                      />
                    </td>
                    <td>{x.product_price}</td>
                    <td>{x.quantity}</td>
                    <td className="buttons">

                      <Popup trigger={<button className="update">
                        <BiEdit />
                      </button>}

                        position="bottom center">
                        <div >
                          <form method="POST" enctype="multipart/form-data" action="https://mindsinaction.com.na/api/product_update.php">
                            <div className="form-control">
                              <input type="hidden" name="old_product_name" value={x.product_name} />
                            </div>
                            <div className="form-control">
                              <input type="text" name="product_name" placeholder={x.product_name} />
                            </div>
                            <div className="form-control">
                              <input type="number" name="product_price" placeholder={x.product_price} />
                            </div>
                            <div className="form-control">
                              <input type="number" name="quantity" placeholder={x.quantity} />
                            </div>
                            <div className="form-control">
                              <input type="file" name="image_url" accept="image/*" />
                            </div>
                            <div className="form-control">
                              <input type="submit" name="submit" />
                            </div>
                          </form>
                        </div>
                      </Popup>
                      <button
                        onClick={() => deleteProduct(x.id)}
                        className="delete"
                      >
                        <RiDeleteBin5Line />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    );
  } else {
    return <Redirect to="/" />;
  }
}