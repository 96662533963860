import { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import validator from "validator";
import Axios from "axios";
import "./ProjectForm.css";

class ProjectForm extends Component {
  //Intializing a state
  state = {
    title: "",
    content: "",
    image_url: "",
    video_url: "",
    start_date: null,
    end_date: null,
    editorState: EditorState.createEmpty(),
    title_validation: "",
    image_url_validation: "",
    video_url_validation: "",
    editorSateValidation: "",
    start_date_validation: "",
    end_date_validation: "",
    file_size: 0,
  };

  //taking text editor's input
  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  //taking the file's input
  onChangeFileHandler = (e) => {
    this.setState({
      image_url: e.target.files[0],
      file_size: e.target.files[0].size,
    });
  };

  //notification
  notify = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
    });
  errorNotify = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
    });

  //add function for submiting the blog's data
  addproject(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", this.state.title);
    formData.append(
      "content",
      draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    );
    formData.append("image_url", this.state.image_url);
    formData.append("video_url", this.state.video_url);
    formData.append("start_date", this.state.start_date);
    formData.append("end_date", this.state.end_date);

    if (
      validator.isEmpty(this.state.title) ||
      this.state.editorState === EditorState.createEmpty()
    ) {
      this.errorNotify("Please, provide the required information!");
      if (validator.isEmpty(this.state.title)) {
        this.setState({
          title_validation: "Title is required!",
        });
      }
      if (this.state.editorState === EditorState.createEmpty) {
        this.setState({
          editorSateValidation: "Project's content is required!",
        });
      }
      if (this.state.start_date === null) {
        this.setState({
          start_date_validation: "Start date is required!",
        });
      }
      if (this.state.end_date === null) {
        this.setState({
          end_date_validation: "End date is required!",
        });
      }
      if (
        validator.isEmpty(this.state.video_url) ||
        this.state.file_size === 0
      ) {
        this.setState({
          video_url_validation:
            "Please provide one of the two(Video url or Blog image)",
        });
      }
    } else {
      Axios.post("https://mindsinaction.com.na/api/project.php", formData)
        .then((res) => {
          switch (res.data.message) {
            case "success":
              this.notify("Successfully posted blog!");
              this.setState({
                editorState: EditorState.createEmpty(),
              });

              break;
            case "file_size_err":
              this.errorNotify(
                "Only images images less than 500MB are allowed!"
              );
              break;
            case "empty_file":
              this.setState({
                validate_profile: "Profile is required!",
              });
              break;
            case "file_type_err":
              this.setState({
                validate_profile:
                  "Only images of this type (jpg,jpeg,png) are allowed!",
              });
              break;
            case "project_exists":
              this.errorNotify("Project already exists!");
              break;
            default:
              break;
          }
          console.log(res);

          
        })
        .catch((error) => {
          this.errorNotify(error.message);
        });
    }
  }

  render() {
    return (
      <div className="main-project-form">
        <form>
          <div className="project-form-control">
            {validator.isEmpty(this.state.title)?<p className="error">{this.state.title_validation}</p>:null}
            <input
              type="text"
              onChange={(e) =>
                this.setState({
                  title: e.target.value,
                })
              }
              placeholder="project title"
            />
          </div>
          <div className="project-form-control" id="editor">
            <p className="error">{this.state.editorSateValidation}</p>
            <Editor
              editorState={this.state.editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editor"
              placeholder="Write a story here"
              onEditorStateChange={this.onEditorStateChange}
            />
          </div>
          <div className="project-form-control">
            <input
              type="text"
              onChange={(e) =>
                this.setState({
                  video_url: e.target.value,
                })
              }
              placeholder="Video url(Optional)"
            />
          </div>
          <div className="project-form-control" id="date">
            <div className="start-date">
              <p>Start date</p>
              <p className="error">{this.state.start_date_validation}</p>
              <DatePicker
                placeholderText="dd/MM/yyyy"
                selected={this.state.start_date}
                onChange={(date) =>
                  this.setState({
                    start_date: date,
                  })
                }
              />
            </div>
            <div className="end-date">
              <p>End date</p>
              <p className="error">{this.state.end_date_validation}</p>
              <DatePicker
                placeholderText="dd/MM/yyyy"
                selected={this.state.end_date}
                onChange={(date) =>
                  this.setState({
                    end_date: date,
                  })
                }
              />
            </div>
          </div>
          <div className="project-form-control">
            <p className="error">{this.state.videoUrlValidation}</p>
            <p>Upload project image(optional)</p>
            <input
              accept="image/*"
              type="file"
              onChange={this.onChangeFileHandler}
              name="file"
            />
          </div>
          <div className="project-form-control" id="s-buttons">
            <input
              type="submit"
              onClick={(e) => this.addproject(e)}
              value="Post project"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default ProjectForm;
