import React, { useState, useEffect } from 'react';
import 'react-tabs/style/react-tabs.css';
import Axios from 'axios';
import './Students.css';
import Header from '../header/Header';
import { BsSearch, IoIosPersonAdd } from 'react-icons/all';
import Popup from '../Popup/Popup';
import StudentForm from './StudentForm/StudentForm';

export default function Student() {
  const [studentInfo, setStudentInfo] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    Axios.get('https://mindsinaction.com.na/api/student.php')
      .then((response) => {
        setStudentInfo(response.data);
      })
      .catch((error) => {
        console.log(error);
        window.addEventListener('unhandledrejection', function (event) {
          console.error(
            'Unhandled rejection (promise: ',
            event.promise,
            ', reason: ',
            event.reason,
            ').'
          );
        });
      });
  }, []);

  if (sessionStorage.getItem('status') != null) {
    return (
      <>
        <Header />
        <div className="student-main">
          <div className>
            <div className="top-search-add">
              <div className="search">
                <div className="search-container">
                  <input type="text" name="" id="" placeholder="Search..." />
                  <BsSearch className="search-icon" />
                </div>
              </div>
              <div className="add">
                <button onClick={() => setOpenPopup(true)}>
                  <IoIosPersonAdd className="add-icon" />
                  Register student
                </button>
              </div>
            </div>
            <Popup
              title="Register student"
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
            >
              <StudentForm />
            </Popup>
            <div className="student-student-data-form">
              {/* <h2>Registered Students</h2> */}

              <table>
                <tr>
                  <th>Name</th>
                  <th>Course</th>
                  <th>Parent name</th>
                  {/* <th>School</th>
                          <th>Grade</th> */}
                  <th>Cellphone</th>
                  {/* <th>Address</th> */}
                  {/* <th>Email</th> */}
                  <th>Date</th>
                </tr>
                {studentInfo.map((x, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        {x.firstname} {x.lastname}
                      </td>
                      <td>{x.course_name}</td>
                      <td>{x.parent_name}</td>
                      {/* <td>{x.school}</td>
                              <td>{x.grade}</td> */}
                      <td>{x.phoneNumber}</td>
                      {/* <td>{x.address}</td> */}
                      {/* <td>{x.email}</td> */}
                      <td>{x.date}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </>
    );
  } else {
  }
}
