import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { CircleToBlockLoading } from 'react-loadingg';
import Login from './screens/Login/Login';
import Home from './screens/Home/Home';
import Admin from './screens/Admin/Admin';
import Blog from './screens/Blog/Blog';
import Product from './screens/Products/Product';
import Project from './screens/projects/Projects';
import Coach from './screens/Coaches/Coach';
import Gallery from './screens/Gallery/Gallery';
import Student from './screens/Students/Students';

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <div className="App">
      {loading ? (
        <div className="loader">
          <CircleToBlockLoading
            size="small"
            speed={4}
            color="#0c50a3"
            loading={loading} //3 secs
          />
        </div>
      ) : (
        <BrowserRouter>
          <Route exact path="/" component={Login} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/students" component={Student} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/product" component={Product} />
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/projects" component={Project} />
          <Route exact path="/coaches" component={Coach} />
          <Route exact path="/blog" component={Blog} />
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
