import React, { useState } from "react";
import logo from "../../assets/login-images/logo.png";
import { RiAccountPinCircleLine, MdEmail, BiLock } from "react-icons/all";
import { NavLink, useHistory } from "react-router-dom";
import "./Login.css";
import Axios from "axios";
import validator from "validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookie from "js-cookie";

toast.configure();

export default function Login() {
  //creating oging details' states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //Form validation
  const [validateEmail, setEmailValidation] = useState("");
  const [passwordValidation, setPasswordValidation] = useState("");
  let history = useHistory();

  //notification
  const notify = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
    });
  const errerNotify = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
    });

  const login = () => {
    if (validator.isEmpty(email) || validator.isEmpty(password)) {
      errerNotify("Please provide the required details!");
      if (validator.isEmpty(email)) {
        setEmailValidation("Email is required!");
      }
      if (validator.isEmpty(password)) {
        setPasswordValidation("Password is required!");
      }
    } else {
      if (!validator.isEmail(email)) {
        errerNotify("Please provide a valid email!");
      } else {
        const data = {
          email: email,
          password: password,
        };

        Axios.post("https://mindsinaction.com.na/api/admin.php", data)
          .then((res) => {
            if (res.data.message === "success") {
              notify("Successfully logged in!");

              //setting a cookie on login success
              Cookie.set("userData", JSON.stringify(res.data.userData));

              //navigating 
              setTimeout(() => {
                history.push("/home");
              }, 2000);

              //setting the user session storage
              sessionStorage.setItem("status", "loggedin");
            } else if (res.data.message === "failed") {
              errerNotify("Invalid username or password!");
            }
          })

          .catch((error) => {
            errerNotify(error.message);
            window.addEventListener("unhandledrejection", function (event) {
              console.error(
                "Unhandled rejection (promise: ",
                event.promise,
                ", reason: ",
                event.reason,
                ")."
              );
            });
          });
      }
    }
  };

  return (
    <div className="login-home">
      <div className="login-top-line"></div>
      <div className="login-header">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <div className="login-panel-name">
          <h3>Admin Pannel</h3>
        </div>
      </div>

      <div className="form" encType="multipart/form-data">
        <div className="login-form-icon-container">
          <div className="login-form-icon">
            <RiAccountPinCircleLine className="ico" />
          </div>
          <h4>Login here</h4>
        </div>
        <p className="error">{validateEmail}</p>
        <div className="login-form-control">
          <div className="input-icon">
            <MdEmail />
          </div>
          <input
            type="email"
            placeholder="Email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <p className="error">{passwordValidation}</p>
        <div className="login-form-control">
          <div className="input-icon">
            <BiLock />
          </div>
          <input
            type="password"
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
        <input type="submit" value="Submit" onClick={login} />
        <NavLink exact to="/">
          Forgot password? Click here.
        </NavLink>
      </div>
    </div>
  );
}
