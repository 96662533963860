import Axios from "axios";
import { useEffect, useState } from "react";
import Header from "../header/Header";
import {
  BsSearch,
  MdPlaylistAdd,
  BiEdit,
  RiDeleteBin5Line,
} from "react-icons/all";
import Popup from "../Popup/Popup";
import ProjectForm from "./ProjectForm/ProjectForm";
import { Redirect } from "react-router";

export default function Project() {
  const [blogData, setBlogData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    Axios.get("https://mindsinaction.com.na/api/project.php")
      .then((response) => {
        setBlogData(response.data);
      })
      .catch((error) => {
        console.log(error);
        window.addEventListener("unhandledrejection", function (event) {
          console.error(
            "Unhandled rejection (promise: ",
            event.promise,
            ", reason: ",
            event.reason,
            ")."
          );
        });
      });
  }, []);
  if (sessionStorage.getItem("status") != null) {
    return (
      <div>
        <Header />
        <div className="blog-container">
          <div className="top-search-add">
            <div className="search">
              <div className="search-container">
                <input type="text" name="" id="" placeholder="Search..." />
                <BsSearch className="search-icon" />
              </div>
            </div>
            <div className="add">
              <button onClick={() => setOpenPopup(true)}>
                <MdPlaylistAdd className="add-icon" />
                Add project
              </button>
            </div>
          </div>
          <Popup
            title="Add project"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
          >
            <ProjectForm />
          </Popup>
          <div className="blog-data">
            <table>
              <tr>
                <th>Title</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
              {blogData.map((x, i) => {
                return (
                  <tr key={i}>
                    <td>{x.title}</td>
                    <td>{x.date}</td>
                    <td className="buttons">
                      <button className="update">
                        <BiEdit />
                      </button>
                      <button className="delete">
                        <RiDeleteBin5Line />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    );
  } else {
    return <Redirect to="/" />;
  }
}
